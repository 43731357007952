import * as React from "react";
import {Fragment} from "react";
import {Popover, Transition} from '@headlessui/react'
import {MenuIcon, XIcon,} from '@heroicons/react/outline'
import {ChevronDownIcon} from '@heroicons/react/solid'
import {StaticImage} from "gatsby-plugin-image";
import {Link} from "gatsby";
import logo from "../images/romvesen-logo-invert.svg";
import anleggIcon from "../images/icon-anlegg.svg";
import energiIcon from "../images/icon-energi.svg";
import landbrukIcon from "../images/icon-landbruk.svg";

const solutions = [
    {
        name: 'Anlegg',
        description: 'Bygg- og anleggsbransjen har et enormt behov for data i forkant av et prosjekt.',
        href: '/bransjer/anlegg',
        icon: anleggIcon,
    },
    {
        name: 'Energi',
        description: 'Energibransjen har et stort behov for innsikt fra luften for å inspisere milevis med kraftledninger og terreng.',
        href: '/bransjer/energi',
        icon: energiIcon,
    },
    {
        name: 'Landbruk',
        description: "Vi har markedets beste droner og sensorsystemer for det moderne presisjonslandbruket.",
        href: '/bransjer/landbruk',
        icon: landbrukIcon,
    },
]
const resources = [
    {
        name: 'Inspeksjon',
        description: 'Vi har markedets beste droner og sensorsystemer for det moderne presisjonslandbruket.',
        href: '/tjenester/inspeksjon',
        icon: landbrukIcon,
    },
    {
        name: 'Kartlegging',
        description: 'Vi har markedets beste droner og sensorsystemer for det moderne presisjonslandbruket.',
        href: '/tjenester/kartlegging',
        icon: landbrukIcon,
    },
    {
        name: 'Grunnundersøkelser',
        description: 'Vi har markedets beste droner og sensorsystemer for det moderne presisjonslandbruket.',
        href: '/tjenester/grunnundersokelser',
        icon: landbrukIcon,
    },
    {
        name: 'Rådgiving',
        description: 'Vi har markedets beste droner og sensorsystemer for det moderne presisjonslandbruket.',
        href: '/tjenester/radgiving',
        icon: landbrukIcon,
    },
    {
        name: 'Opplæring/Kurs',
        description: 'Vi har markedets beste droner og sensorsystemer for det moderne presisjonslandbruket.',
        href: '/tjenester/opplaering',
        icon: landbrukIcon,
    },
    {
        name: 'Romvesen Cloud',
        description: 'Vi har markedets beste droner og sensorsystemer for det moderne presisjonslandbruket.',
        href: '/tjenester/romvesen-cloud',
        icon: landbrukIcon,
    },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Header3(props) {
    return (
        <Popover className="relative">
            {({ open }) => (
                <>
                    <div className={`${!props.landing && `bg-secondary`} max-w-7xl mx-auto flex justify-between items-center px-4 py-6 sm:px-6 md:justify-start md:space-x-10`}>
                        <div className="flex justify-start lg:w-0 lg:flex-1">
                            <Link to="/">
                                <span className="sr-only">Romvesen</span>
                                <img className="h-8 w-auto sm:h-10" src={logo} alt="Romvesen" />
                                {/*<StaticImage placeholder="tracedSVG" loading="eager" className="h-8 w-auto sm:h-10" src="../images/romvesen-logo-invert.svg" width={75} alt="Romvesen AS" />*/}
                            </Link>
                        </div>
                        <div className="-mr-2 -my-2 md:hidden">
                            <Popover.Button className="bg-primary rounded-md p-2 inline-flex items-center justify-center text-secondary hover:text-white focus:outline-none">
                                <span className="sr-only">Open menu</span>
                                <MenuIcon className="h-6 w-6" aria-hidden="true" />
                            </Popover.Button>
                        </div>
                        <Popover.Group as="nav" className="hidden md:flex space-x-10">
                            <Popover className="relative">
                                {({ open }) => (
                                    <>
                                        <Popover.Button
                                            className={classNames(
                                                open ? 'text-white' : 'text-gray-200',
                                                'group rounded-md inline-flex items-center text-base font-medium hover:text-white focus:outline-none'
                                            )}
                                        >
                                            <span>Bransjer</span>
                                            <ChevronDownIcon
                                                className={classNames(
                                                    open ? 'text-white' : 'text-gray-200',
                                                    'ml-2 h-5 w-5 group-hover:text-white'
                                                )}
                                                aria-hidden="true"
                                            />
                                        </Popover.Button>

                                        <Transition
                                            show={open}
                                            as={Fragment}
                                            enter="transition ease-out duration-200"
                                            enterFrom="opacity-0 translate-y-1"
                                            enterTo="opacity-100 translate-y-0"
                                            leave="transition ease-in duration-150"
                                            leaveFrom="opacity-100 translate-y-0"
                                            leaveTo="opacity-0 translate-y-1"
                                        >
                                            <Popover.Panel
                                                static
                                                className="absolute z-10 -ml-4 mt-3 transform w-screen max-w-md lg:max-w-2xl lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                                            >
                                                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                                    <div className="relative grid gap-6 bg-secondary-light px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
                                                        {solutions.map((item) => (
                                                            <Link
                                                                key={item.name}
                                                                to={item.href}
                                                                className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-800"
                                                            >
                                                                <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-primary text-secondary sm:h-12 sm:w-12">
                                                                    <img aria-hidden="true" src={item.icon} alt={item.name} />
                                                                    {/*<item.icon className="h-6 w-6" aria-hidden="true" />*/}
                                                                </div>
                                                                <div className="ml-4">
                                                                    <p className="text-base font-medium text-gray-100">{item.name}</p>
                                                                    <p className="mt-1 text-sm text-gray-200">{item.description}</p>
                                                                </div>
                                                            </Link>
                                                        ))}
                                                    </div>
                                                    {/*<div className="p-5 bg-gray-50 sm:p-8">*/}
                                                    {/*    <a href="#" className="-m-3 p-3 flow-root rounded-md hover:bg-gray-100">*/}
                                                    {/*        <div className="flex items-center">*/}
                                                    {/*            <div className="text-base font-medium text-gray-900">Enterprise</div>*/}
                                                    {/*            <span className="ml-3 inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium leading-5 bg-indigo-100 text-indigo-800">*/}
                                                    {/*              New*/}
                                                    {/*            </span>*/}
                                                    {/*        </div>*/}
                                                    {/*        <p className="mt-1 text-sm text-gray-500">*/}
                                                    {/*            Empower your entire team with even more advanced tools.*/}
                                                    {/*        </p>*/}
                                                    {/*    </a>*/}
                                                    {/*</div>*/}
                                                </div>
                                            </Popover.Panel>
                                        </Transition>
                                    </>
                                )}
                            </Popover>

                            <Popover className="relative">
                                {({ open }) => (
                                    <>
                                        <Popover.Button
                                            className={classNames(
                                                open ? 'text-white' : 'text-gray-200',
                                                'group rounded-md inline-flex items-center text-base font-medium hover:text-white focus:outline-none'
                                            )}
                                        >
                                            <span>Tjenester</span>
                                            <ChevronDownIcon
                                                className={classNames(
                                                    open ? 'text-white' : 'text-gray-200',
                                                    'ml-2 h-5 w-5 group-hover:text-white'
                                                )}
                                                aria-hidden="true"
                                            />
                                        </Popover.Button>

                                        <Transition
                                            show={open}
                                            as={Fragment}
                                            enter="transition ease-out duration-200"
                                            enterFrom="opacity-0 translate-y-1"
                                            enterTo="opacity-100 translate-y-0"
                                            leave="transition ease-in duration-150"
                                            leaveFrom="opacity-100 translate-y-0"
                                            leaveTo="opacity-0 translate-y-1"
                                        >
                                            <Popover.Panel
                                                static
                                                className="absolute z-10 -ml-4 mt-3 transform w-screen max-w-md lg:max-w-2xl lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                                            >
                                                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                                    <div className="relative grid gap-6 bg-secondary-light px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
                                                        {resources.map((item) => (
                                                            <Link
                                                                key={item.name}
                                                                to={item.href}
                                                                className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-800"
                                                            >
                                                                {/*<div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-primary text-secondary sm:h-12 sm:w-12">*/}
                                                                {/*    <img aria-hidden="true" src={item.icon} alt={item.name} />*/}
                                                                {/*    /!*<item.icon className="h-6 w-6" aria-hidden="true" />*!/*/}
                                                                {/*</div>*/}
                                                                <div className="ml-4">
                                                                    <p className="text-base font-medium text-gray-100">{item.name}</p>
                                                                    {/*<p className="mt-1 text-sm text-gray-200">{item.description}</p>*/}
                                                                </div>
                                                            </Link>
                                                        ))}
                                                    </div>
                                                    {/*<div className="p-5 bg-gray-50 sm:p-8">*/}
                                                    {/*    <a href="#" className="-m-3 p-3 flow-root rounded-md hover:bg-gray-100">*/}
                                                    {/*        <div className="flex items-center">*/}
                                                    {/*            <div className="text-base font-medium text-gray-900">Enterprise</div>*/}
                                                    {/*            <span className="ml-3 inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium leading-5 bg-indigo-100 text-indigo-800">*/}
                                                    {/*              New*/}
                                                    {/*            </span>*/}
                                                    {/*        </div>*/}
                                                    {/*        <p className="mt-1 text-sm text-gray-500">*/}
                                                    {/*            Empower your entire team with even more advanced tools.*/}
                                                    {/*        </p>*/}
                                                    {/*    </a>*/}
                                                    {/*</div>*/}
                                                </div>
                                            </Popover.Panel>
                                        </Transition>
                                    </>
                                )}
                            </Popover>

                            <Link to="/om-oss" className="text-base font-medium text-gray-200 hover:text-white">
                                Om Oss
                            </Link>
                            <a href="https://shop.romvesen.as" target="_blank" rel="noreferrer" className="text-base font-medium text-gray-200 hover:text-white">
                                Nettbutikk
                            </a>
                            <a href="https://romvesen.cloud" target="_blank" rel="noreferrer" className="text-base font-medium text-gray-200 hover:text-white">
                                Romvesen Cloud
                            </a>
                            <a href="https://droneoperasjon.romvesen.as" target="_blank" rel="noreferrer" className="text-base font-medium text-gray-200 hover:text-white">
                                Droneoperasjon
                            </a>
                        </Popover.Group>
                        <div className="hidden lg:flex items-center justify-end lg:flex-1 lg:w-0">
                            <Link to="/kontakt-oss"
                                  className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-secondary bg-primary hover:bg-primary-light">
                                Kontakt oss
                            </Link>
                        </div>
                    </div>

                    <Transition
                        show={open}
                        as={Fragment}
                        enter="duration-200 ease-out"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="duration-100 ease-in"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Popover.Panel
                            focus
                            static
                            className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden z-10"
                        >
                            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-secondary-light divide-y-2 divide-gray-50">
                                <div className="pt-5 pb-6 px-5">
                                    <div className="flex items-center justify-between">
                                        <Link to="/">
                                            <StaticImage placeholder="none" className="h-8 w-auto" src="../images/romvesen-logo-invert.svg" width={75} alt="Romvesen AS" />
                                        </Link>
                                        <div className="-mr-2">
                                            <Popover.Button className="bg-primary rounded-md p-2 inline-flex items-center justify-center text-secondary hover:text-white focus:outline-none">
                                                <span className="sr-only">Close menu</span>
                                                <XIcon className="h-6 w-6" aria-hidden="true" />
                                            </Popover.Button>
                                        </div>
                                    </div>
                                    <div className="mt-6">
                                        <div className="text-white text-xl font-medium text-center pb-4">
                                            Bransjer
                                        </div>
                                        <nav className="grid grid-cols-2 gap-7">
                                            {solutions.map((item) => (
                                                <Link
                                                    key={item.name}
                                                    to={item.href}
                                                    className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50"
                                                >
                                                    <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-primary text-secondary">
                                                        <img aria-hidden="true" src={item.icon} alt={item.name} />
                                                        {/*<item.icon className="h-6 w-6" aria-hidden="true" />*/}
                                                    </div>
                                                    <div className="ml-4 text-base font-medium text-gray-100">{item.name}</div>
                                                </Link>
                                            ))}
                                        </nav>
                                        <div className="text-white text-xl font-medium text-center p-4">
                                            Tjenester
                                        </div>
                                        <nav className="grid grid-cols-2 gap-7">
                                            {resources.map((item) => (
                                                <Link
                                                    key={item.name}
                                                    to={item.href}
                                                    className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50"
                                                >
                                                    <div className="ml-4 text-base font-medium text-gray-100">{item.name}</div>
                                                </Link>
                                            ))}
                                        </nav>
                                    </div>
                                </div>
                                <div className="py-6 px-5">
                                    <nav className="grid grid-cols-1 gap-7 text-center">
                                        <Link to="/om-oss" className="text-base font-medium text-gray-100 hover:text-gray-700">
                                            Om Oss
                                        </Link>

                                        <a href="https://shop.romvesen.as/" target="_blank" rel="noreferrer" className="text-base font-medium text-gray-100 hover:text-gray-700">
                                            Nettbutikk
                                        </a>

                                        <a href="https://romvesen.cloud" target="_blank" rel="noreferrer" className="text-base font-medium text-gray-100 hover:text-gray-700">
                                            Romvesen Cloud
                                        </a>

                                        <a href="https://droneoperasjon.romvesen.as" target="_blank" rel="noreferrer" className="text-base font-medium text-gray-100 hover:text-gray-700">
                                            Droneoperasjon
                                        </a>

                                        {/*<a href="#" className="text-base font-medium text-gray-900 hover:text-gray-700">*/}
                                        {/*    Enterprise*/}
                                        {/*</a>*/}
                                        {/*{resources.map((item) => (*/}
                                        {/*    <a*/}
                                        {/*        key={resources.name}*/}
                                        {/*        href={item.href}*/}
                                        {/*        className="text-base font-medium text-gray-900 hover:text-gray-700"*/}
                                        {/*    >*/}
                                        {/*        {item.name}*/}
                                        {/*    </a>*/}
                                        {/*))}*/}
                                    </nav>
                                    <div className="mt-6">
                                        <Link
                                            to="/kontakt-oss"
                                            className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-secondary bg-primary"
                                        >
                                            Kontakt oss
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    )
}